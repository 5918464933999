import { default as _91id_93r4vwAfPtRXMeta } from "/app/pages/CampaignOverview/[id].vue?macro=true";
import { default as indexfLuONS6gpXMeta } from "/app/pages/CampaignOverview/index.vue?macro=true";
import { default as _91id_93rQaJ3bE3HbMeta } from "/app/pages/campaigns/[id].vue?macro=true";
import { default as indexXQrcYCB1fsMeta } from "/app/pages/campaigns/contents/index.vue?macro=true";
import { default as indexJjfgnQLEBBMeta } from "/app/pages/campaigns/index.vue?macro=true";
import { default as indexXfyqpoTP81Meta } from "/app/pages/campaigns/products/index.vue?macro=true";
import { default as CampaignSpecficWW61wePVK9Meta } from "/app/pages/CampaignSpecfic.vue?macro=true";
import { default as CompaignManagementcRVHaLLJQNMeta } from "/app/pages/CompaignManagement.vue?macro=true";
import { default as indexXampRaqRJoMeta } from "/app/pages/discovery/index.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_932CCC4AWIaoMeta } from "/app/pages/influencer/[id].vue?macro=true";
import { default as indexFogixKafXYMeta } from "/app/pages/influencer/index.vue?macro=true";
import { default as _91id_93qgsFhiaz7fMeta } from "/app/pages/lead-group/[id].vue?macro=true";
import { default as index4wo0k90eYTMeta } from "/app/pages/lead-group/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexlqMPKE5eVMMeta } from "/app/pages/overview/index.vue?macro=true";
import { default as PaymentsPjhyFG86liMeta } from "/app/pages/Payments.vue?macro=true";
import { default as products08Bd1RJ93nMeta } from "/app/pages/products.vue?macro=true";
import { default as _91id_93YdB9WWcesgMeta } from "/app/pages/reach-out/[id].vue?macro=true";
import { default as indexUFlcFMg7nLMeta } from "/app/pages/reach-out/index.vue?macro=true";
import { default as indexG21OGZWb3CMeta } from "/app/pages/settings/menu/index.vue?macro=true";
import { default as index10XHYAI6aQMeta } from "/app/pages/settings/user/index.vue?macro=true";
export default [
  {
    name: "CampaignOverview-id",
    path: "/CampaignOverview/:id()",
    component: () => import("/app/pages/CampaignOverview/[id].vue").then(m => m.default || m)
  },
  {
    name: "CampaignOverview",
    path: "/CampaignOverview",
    component: () => import("/app/pages/CampaignOverview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-id",
    path: "/campaigns/:id()",
    component: () => import("/app/pages/campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: "campaigns-contents",
    path: "/campaigns/contents",
    component: () => import("/app/pages/campaigns/contents/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/app/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-products",
    path: "/campaigns/products",
    component: () => import("/app/pages/campaigns/products/index.vue").then(m => m.default || m)
  },
  {
    name: "CampaignSpecfic",
    path: "/CampaignSpecfic",
    component: () => import("/app/pages/CampaignSpecfic.vue").then(m => m.default || m)
  },
  {
    name: "CompaignManagement",
    path: "/CompaignManagement",
    component: () => import("/app/pages/CompaignManagement.vue").then(m => m.default || m)
  },
  {
    name: "discovery",
    path: "/discovery",
    component: () => import("/app/pages/discovery/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: index4aGTGBC5tJMeta || {},
    component: () => import("/app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "influencer-id",
    path: "/influencer/:id()",
    component: () => import("/app/pages/influencer/[id].vue").then(m => m.default || m)
  },
  {
    name: "influencer",
    path: "/influencer",
    component: () => import("/app/pages/influencer/index.vue").then(m => m.default || m)
  },
  {
    name: "lead-group-id",
    path: "/lead-group/:id()",
    component: () => import("/app/pages/lead-group/[id].vue").then(m => m.default || m)
  },
  {
    name: "lead-group",
    path: "/lead-group",
    component: () => import("/app/pages/lead-group/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/app/pages/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "Payments",
    path: "/Payments",
    component: () => import("/app/pages/Payments.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/app/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "reach-out-id",
    path: "/reach-out/:id()",
    component: () => import("/app/pages/reach-out/[id].vue").then(m => m.default || m)
  },
  {
    name: "reach-out",
    path: "/reach-out",
    component: () => import("/app/pages/reach-out/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-menu",
    path: "/settings/menu",
    component: () => import("/app/pages/settings/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-user",
    path: "/settings/user",
    component: () => import("/app/pages/settings/user/index.vue").then(m => m.default || m)
  }
]